import Posthog, { PostHogConfig } from 'posthog-js'

const apiKey = window.App?.ENV.POSTHOG_API_KEY
const config: Partial<PostHogConfig> = {
  api_host: 'https://eu.i.posthog.com',
  person_profiles: 'identified_only',
}
const shouldInitPosthog = import.meta.env.PROD && apiKey

/**
 * Global instance of Posthog; use it to send tracking events
 * @link to docs https://posthog.com/docs/libraries/vue-js
 */
export const posthog = shouldInitPosthog
  ? Posthog.init(apiKey, config)
  : undefined

if (posthog) {
  posthog.register({
    release: window.App?.ENV.REVISION,
  })
}

export type AnalyticsEventProperties = { [key: string]: unknown }

/**
 * Composable for easy tracking of analytics events
 */
export const useAnalytics = () => {
  /**
   * track an analytics event; as we might add different providers in the future, this is a wrapper to all of them
   * @param action the name of the event aka action
   * @param entity the entity the action has been performed on
   * @param properties a collection of additional properties to pass with the tracking event
   */
  const trackEvent = (
    action: string,
    entity: string,
    properties?: AnalyticsEventProperties
  ) => {
    posthog?.capture(`${action} ${entity}`, {
      action,
      entity,
      ...properties,
    })
  }

  /**
   * track an analytics event by wrapping a function call
   * @param action the name of the event aka action
   * @param entity the entity the action has been performed on
   * @param properties a collection of additional properties to pass with the tracking event
   * @example `
   * const { wrapTrackEvent } = useAnalytics();
   * wrapTrackEvent('delete', 'user')(deleteUser);
   * `
   */
  const wrapTrackEvent = (
    action: string,
    entity: string,
    properties?: AnalyticsEventProperties
  ) => {
    const track = () => trackEvent(action, entity, properties)
    return <T extends unknown[], U>(fn: (...args: T) => U) => {
      return (...args: T): U => {
        const result = fn(...args)
        if (result instanceof Promise) {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          result.then(() => track()).catch(() => {})
        } else {
          track()
        }
        return result
      }
    }
  }

  return {
    trackEvent,
    wrapTrackEvent,
  }
}
